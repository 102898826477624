import * as React from "react"
import Layout from "../components/layout"
import {StaticImage} from "gatsby-plugin-image"
import BrandedDivider from "../components/branded_divider"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import {
  cardTitle,
  cardContent
} from "../css/index.module.css"
import TelephoneLink from "../components/telephone_link"
import PageBanner from "../components/page_banner"
import {SEO} from "../components/seo"

const AboutPage = () => {
  return (
    <Layout pageTitle="About">
      <PageBanner title="Experience Matters">
        <StaticImage
          alt="EnginuityUSA - About"
          src="../images/engineering.jpg"
        />
      </PageBanner>

      <Container className="py-5">
        <Row className="py-3 no-gutters">
          <Col>
            <h4 className={cardTitle}><BrandedDivider/>Who We Are</h4>
            <p className={cardContent}>The Engineers at Enginuity have over 45 years of active experience as Engineers
              and over 40 years of experience as Professional Engineers, holding Licenses in
              multiple states.
            </p>
            <p className={cardContent}>They have been directly involved in the engineering design of
              hundreds of projects for most Commercial and Industrial building types including:
              Education, Hospitality, Multi-Family, Healthcare, Libraries, Museums,
              Entertainment, Restaurant, Retail, Government, International, Pharmaceutical,
              Mining, Ports and Terminals.
            </p>
            <p className={cardContent}>Our Engineers have been appointed by Government Leaders to serve on commissions
              to
              develop regulations used
              in industry today. They have taught as adjunct instructors at multiple Universities. Their experience
              in engineering design and construction is important when planning and executing
              new facility construction, existing facility expansion and renovation. They have
              successfully completed more than 1000 projects from small to projects exceeding
              500 million dollars. Enginuity is currently licensed in the States of Florida, Georgia,
              Alabama, South Carolina, Mississippi, and Louisiana.
            </p>
            <p className={cardContent}>We are a Veteran Owned Business.</p>
          </Col>
        </Row>

        <Row className="py-3">
          <Col>
            <h4 className={cardTitle}><BrandedDivider/>Learn More</h4>
            <p className={cardContent}>Contact us in Tampa, FL, at <TelephoneLink/> to learn more about
              our company and our consulting services.</p>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const Head = () => <SEO title={"Enginuity USA | About"} />

export default AboutPage
